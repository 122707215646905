<template>
  <div class="wrapper">
    <div class="score-detail">
      <div class="item">
        <span>客户系统评级：</span>
        <TagLv v-if="detail.sysHitGrade == 'A'" name="A"></TagLv>
        <TagLv v-if="detail.sysHitGrade == 'B'" name="B"></TagLv>
        <TagLv v-if="detail.sysHitGrade == 'C'" name="C"></TagLv>
        <TagLv v-if="detail.sysHitGrade == 'D'" name="D"></TagLv>
      </div>
      <div class="item">
        <span>系统评分：</span>
        <span class="score">{{ detail.sysHitScore >= 0 ? detail.sysHitScore + '分' : '' }}</span>
      </div>
      <div class="item">
        <span>系统建议：</span>
        <span style="color:#bd3124">{{ detail.sysHitSuggestDesc }}</span>
        <span v-if="!detail.sysHitSuggestDesc">/</span>
      </div>
    </div>
    <div class="title">系统评分详情</div>
    <table class="table-score" border="1" style="width:100%;text-align: center" cellspacing="0">
      <thead>
        <tr align="center">
          <th style="text-align: center">序号</th>
          <th style="text-align: center">评分项命中规则</th>
          <th style="text-align: center">分值</th>
          <th style="text-align: center">机审建议</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in detail.hitScoreBillList" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.ruleName }}</td>
          <td>{{ item.hitScore }}</td>
          <td>{{ item.hitSuggestDesc }}</td>
        </tr>
      </tbody>
    </table>
    <div class="title">宝系智租风险评估</div>
    <div class="baoxi">
      <div class="sug" v-if="alipay.riskRank">
        <div class="s rank1">
          极低风险
          <div v-if="alipay.riskRank == 'RANK1'" class="sug-tip t1">
            风险建议：建议直接通过
            <div class="arrow"></div>
          </div>
        </div>
        <div class="s rank2">
          低风险
          <div v-if="alipay.riskRank == 'RANK2'" class="sug-tip t2">
            风险建议：建议通过
            <div class="arrow"></div>
          </div>
        </div>
        <div class="s rank3">
          中风险
          <div v-if="alipay.riskRank == 'RANK3'" class="sug-tip t3">
            风险建议：建议审核
            <div class="arrow"></div>
          </div>
        </div>
        <div class="s rank4">
          高风险
          <div v-if="alipay.riskRank == 'RANK4'" class="sug-tip t4">
            风险建议：建议审核
            <div class="arrow"></div>
          </div>
        </div>
        <div class="s rank5">
          极高风险
          <div v-if="alipay.riskRank == 'RANK5'" class="sug-tip t5">
            风险建议：建议拒绝
            <div class="arrow"></div>
          </div>
        </div>
      </div>
      <table class="table-score" border="1" style="width:100%;text-align: center" cellspacing="0">
        <thead>
          <tr align="center">
            <th style="text-align: center">风险项</th>
            <th style="text-align: center">风险等级</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="alipay.riskList && alipay.riskList.length > 0">
            <tr v-for="(item, i) in alipay.riskList" :key="i">
              <td>{{ item.riskName }}</td>
              <td>
                <div class="rank-tag tag1" v-if="item.riskRank == 'RANK1'">极低风险</div>
                <div class="rank-tag tag2" v-if="item.riskRank == 'RANK2'">低风险</div>
                <div class="rank-tag tag3" v-if="item.riskRank == 'RANK3'">中风险</div>
                <div class="rank-tag tag4" v-if="item.riskRank == 'RANK4'">高风险</div>
                <div class="rank-tag tag5" v-if="item.riskRank == 'RANK5'">极高风险</div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td>/</td>
            <td>/</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="title">重点信息标注</div>
    <table class="table-important" border="1" style="width:100%;text-align: center" cellspacing="0">
      <thead>
        <tr align="center">
          <th style="text-align: center">运营商在网时长</th>
          <th style="text-align: center">自然人可信度识别</th>
          <th style="text-align: center">金融黑名单等级</th>
          <th style="text-align: center">反欺诈评分</th>
          <th style="text-align: center">债偿压力指数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ youFen.netTime || '/' }}</td>
          <td>{{ youFen.identityCredence||'/' }}</td>
          <td>{{ youFen.blacklistLevel||'/' }}</td>
          <td>{{ baiRong.afRuleScore||'/' }}</td>
          <td>{{ baiRong.debtRepayStress||'/' }}</td>
        </tr>
      </tbody>
    </table>
    <div class="title">网贷行为评估</div>
    <div class="sub-title">
      <i class="el-icon-s-order"></i>
      申请记录
    </div>
    <!-- 表格1 -->
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:10px" cellspacing="0">
      <tr class="table-bg1">
        <td colspan="2">申请命中机构数</td>
      </tr>
      <tr>
        <td colspan="2">{{ xinYanSpray.applyHitOrgNum || '/' }}</td>
      </tr>
      <tr class="table-bg1">
        <td>申请命中消金机构数</td>
        <td>申请命中网贷类机构数</td>
      </tr>
      <tr>
        <td>{{ xinYanSpray.applyHitConsumeLoanOrgNum || '/' }}</td>
        <td>{{ xinYanSpray.applyHitNetLoanOrgNum ||'/'}}</td>
      </tr>
    </table>

    <!-- 表格2 -->
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:10px" cellspacing="0">
      <tr class="table-bg1">
        <td colspan="4">近7天</td>
        <td colspan="2">近15天</td>
        <td colspan="2">近1个月</td>
      </tr>
      <tr>
        <td>线上消费分期的次数</td>
        <td>线上消费分期的机构数</td>
        <td>持牌小贷机构申请次数</td>
        <td>持牌小贷机构申请机构数</td>
        <td>持牌融资租赁机构申请次数</td>
        <td>非银机构周末申请机构数</td>
        <td>非银机构申请次数</td>
        <td>申请汽车金融的机构数</td>
      </tr>
      <tr>
        <td>{{ baiRong.d7CoonAllNum || '/' }}</td>
        <td>{{ baiRong.d7CoonOrgNum ||'/'}}</td>
        <td>{{ baiRong.d7NbankSloanAllNum ||'/'}}</td>
        <td>{{ baiRong.d7NbankSloanOrgNum||'/' }}</td>
        <td>{{ baiRong.d15NbankFinleaAllNum ||'/'}}</td>
        <td>{{ baiRong.d15NbankWeekOrgNum ||'/'}}</td>
        <td>{{ baiRong.m1IdNbankAllNum ||'/'}}</td>
        <td>{{ baiRong.m1AfOrgNum||'/' }}</td>
      </tr>
    </table>

    <!-- 表格3 -->
    <table class="table-score" border="1" style="width:100%;text-align: center;" cellspacing="0">
      <tr class="table-bg1">
        <td>近3个月</td>
        <td colspan="4">近6个月</td>
      </tr>
      <tr>
        <td>非银机构申请次数</td>
        <td>非银机构申请次数</td>
        <td>非银机构-其他申请次数</td>
        <td>非银机构-消费类分期机构申请次数</td>
        <td>按身份证号查询在非银机构申请次数</td>
      </tr>
      <tr>
        <td>{{ baiRong.m3IdNbankAllNum || '/' }}</td>
        <td>{{ baiRong.m6NbankAllNum ||'/'}}</td>
        <td>{{ baiRong.m6NbankOthAllNum||'/' }}</td>
        <td>{{ baiRong.m6NbankCfAllNum||'/' }}</td>
        <td>{{ baiRong.m6IdNbankAllNum ||'/'}}</td>
      </tr>
    </table>

    <div class="sub-title">
      <i class="el-icon-coin"></i>
      放款记录
    </div>
    <!--表格1 -->
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:10px" cellspacing="0">
      <tr class="table-bg1">
        <td colspan="2">网络贷款类机构数</td>
        <td colspan="2">消金贷款类机构数</td>
        <td colspan="2">最后一次贷款放款时间</td>
      </tr>
      <tr>
        <td colspan="2">{{ xinYanSpray.netLoanOrgNum || '/' }}</td>
        <td colspan="2">{{ xinYanSpray.consumeLoanOrgNum ||'/'}}</td>
        <td colspan="2">{{ xinYanSpray.lastLoanDistributeTime ||'/'}}</td>
      </tr>
      <tr class="table-bg1">
        <td>贷款机构数据</td>
        <td>近1个月</td>
        <td>近3个月</td>
        <td>近6个月</td>
        <td>近12个月</td>
        <td>近24个月</td>
      </tr>
      <tr>
        <td>贷款机构数</td>
        <td>{{ xinYanSpray.m1LoanOrgNum }}</td>
        <td>{{ xinYanSpray.m3LoanOrgNum }}</td>
        <td>{{ xinYanSpray.m6LoanOrgNum }}</td>
        <td>{{ xinYanSpray.m12LoanOrgNum }}</td>
        <td>{{ xinYanSpray.m24LoanOrgNum }}</td>
      </tr>
      <tr>
        <td>消金类贷款机构数</td>
        <td>{{ xinYanSpray.m1ConsumeLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m3ConsumeLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m6ConsumeLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m12ConsumeLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m24ConsumeLoanOrgNum }}</td>
      </tr>
      <tr>
        <td>网贷类贷款机构数</td>
        <td>{{ xinYanSpray.m1NetLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m3NetLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m6NetLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m12NetLoanOrgNum }}</td>
        <td>{{ xinYanSpray.m24NetLoanOrgNum }}</td>
      </tr>
    </table>

    <!--表格2 -->
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:0px" cellspacing="0">
      <tr class="table-bg1">
        <td>放款数据</td>
        <td>近1个月</td>
        <td>近3个月</td>
        <td>近6个月</td>
        <td>近12个月</td>
        <td>近24个月</td>
      </tr>
      <tr>
        <td>贷款笔数</td>
        <td>{{ xinYanSpray.m1LoanNum }}</td>
        <td>{{ xinYanSpray.m3LoanNum }}</td>
        <td>{{ xinYanSpray.m6LoanNum }}</td>
        <td>{{ xinYanSpray.m12LoanNum }}</td>
        <td>{{ xinYanSpray.m24LoanNum }}</td>
      </tr>
      <tr>
        <td>贷款总金额</td>
        <td>{{ xinYanSpray.m1LoanAmount }}</td>
        <td>{{ xinYanSpray.m3LoanAmount }}</td>
        <td>{{ xinYanSpray.m6LoanAmount }}</td>
        <td>{{ xinYanSpray.m12LoanAmount }}</td>
        <td>{{ xinYanSpray.m24LoanAmount }}</td>
      </tr>
    </table>

    <div class="sub-title">
      <i class="el-icon-s-claim"></i>
      履约记录
    </div>
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:10px" cellspacing="0">
      <tr class="table-bg1">
        <td style="background: #e6a4ab;" colspan="2">正常还款订单数占贷款总订单数比例</td>
        <td colspan="2">贷款已结清订单数</td>
        <td colspan="2">最近一次履约距今天数</td>
      </tr>
      <tr>
        <td colspan="2">{{ xinYanSpray.normalRepayOrderRate || '/' }}</td>
        <td colspan="2">{{ xinYanSpray.settledOrderNum || '/' }}</td>
        <td colspan="2">{{ xinYanSpray.lastPerformanceRecentlyDay || '/' }}</td>
      </tr>
      <tr class="table-bg1">
        <td>履约记录</td>
        <td>近1个月</td>
        <td>近3个月</td>
        <td>近6个月</td>
        <td>近12个月</td>
        <td>近24个月</td>
      </tr>
      <tr>
        <td>履约贷款总金额</td>
        <td>{{ xinYanSpray.m1PerformanceLoanAmount }}</td>
        <td>{{ xinYanSpray.m3PerformanceLoanAmount }}</td>
        <td>{{ xinYanSpray.m6PerformanceLoanAmount }}</td>
        <td>{{ xinYanSpray.m12PerformanceLoanAmount }}</td>
        <td>{{ xinYanSpray.m24PerformanceLoanAmount }}</td>
      </tr>
      <tr>
        <td>履约贷款次数</td>
        <td>{{ xinYanSpray.m1PerformanceLoanNum }}</td>
        <td>{{ xinYanSpray.m3PerformanceLoanNum }}</td>
        <td>{{ xinYanSpray.m6PerformanceLoanNum }}</td>
        <td>{{ xinYanSpray.m12PerformanceLoanNum }}</td>
        <td>{{ xinYanSpray.m24PerformanceLoanNum }}</td>
      </tr>
      <tr>
        <td>失败扣款笔数</td>
        <td>{{ xinYanSpray.m1FailDeductNum }}</td>
        <td>{{ xinYanSpray.m3FailDeductNum }}</td>
        <td>{{ xinYanSpray.m6FailDeductNum }}</td>
        <td>{{ xinYanSpray.m12FailDeductNum }}</td>
        <td>{{ xinYanSpray.m24FailDeductNum }}</td>
      </tr>
    </table>

    <div class="sub-title">
      <i class="el-icon-s-release"></i>
      逾期记录
    </div>
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:10px" cellspacing="0">
      <tr class="table-bg1">
        <td colspan="2">最大逾期金额</td>
        <td colspan="2">最长逾期天数</td>
        <td colspan="2">当前逾期机构数</td>
      </tr>
      <tr>
        <td colspan="2">{{ xinYanProbe.maxOverdueAmount || '/' }}</td>
        <td colspan="2">{{ xinYanProbe.maxOverdueDay || '/' }}</td>
        <td colspan="2">{{ xinYanProbe.currOverdueOrgNum || '/' }}</td>
      </tr>
      <tr class="table-bg1">
        <td style="background: #e6a4ab;">近12月逾期次数</td>
        <td style="background: #e6a4ab;">近24月逾期次数</td>
        <td>最近逾期距今天数</td>
        <td>最早逾期距今天数</td>
        <td colspan="2">逾期最大金额距今最近天数</td>
      </tr>
      <tr>
        <td>{{ youFen.m12OverdueNum || '/' }}</td>
        <td>{{ youFen.m24OverdueNum ||'/'}}</td>
        <td>{{ youFen.overdueRecentlyDay||'/' }}</td>
        <td>{{ youFen.overdueHistoryDay||'/' }}</td>
        <td colspan="2">{{ youFen.overdueMaxMoneyRecentlyDay||'/' }}</td>
      </tr>
      <tr class="table-bg1">
        <td>履约记录</td>
        <td>近1个月</td>
        <td>近3个月</td>
        <td>近6个月</td>
        <td>近12个月</td>
        <td>近24个月</td>
      </tr>
      <tr>
        <td>MO+逾期贷款笔数</td>
        <td>{{ xinYanSpray.m1M0OverdueLoanNum }}</td>
        <td>{{ xinYanSpray.m3M0OverdueLoanNum }}</td>
        <td>{{ xinYanSpray.m6M0OverdueLoanNum }}</td>
        <td>{{ xinYanSpray.m12M0OverdueLoanNum }}</td>
        <td>{{ xinYanSpray.m24M0OverdueLoanNum }}</td>
      </tr>
      <tr>
        <td>累计逾期金额</td>
        <td>{{ xinYanSpray.m1TotalOverdueAmount }}</td>
        <td>{{ xinYanSpray.m3TotalOverdueAmount }}</td>
        <td>{{ xinYanSpray.m6TotalOverdueAmount }}</td>
        <td>{{ xinYanSpray.m12TotalOverdueAmount }}</td>
        <td>{{ xinYanSpray.m24TotalOverdueAmount }}</td>
      </tr>
    </table>

    <div class="title">法院风险信息</div>
    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:10px" cellspacing="0">
      <tr class="table-bg1">
        <td>个人涉诉总数</td>
        <td>裁判文书</td>
        <td>开庭公告</td>
        <td>执行公告</td>
        <td>失信公告</td>
        <td>案件流程</td>
        <td>曝光台</td>
      </tr>
      <tr v-if="youFen.personInvolvement">
        <td>{{ youFen.personInvolvement.count || '/' }}</td>
        <td>{{ youFen.personInvolvement.judgeDocumentCount }}</td>
        <td>{{ youFen.personInvolvement.openCourtNoticeCount }}</td>
        <td>{{ youFen.personInvolvement.executeNoticeCount }}</td>
        <td>{{ youFen.personInvolvement.dishonestyNoticeCount }}</td>
        <td>{{ youFen.personInvolvement.caseProduceCount }}</td>
        <td>{{ youFen.personInvolvement.exposureCount }}</td>
      </tr>
      <tr v-else>
        <td>/</td>
        <td>/</td>
        <td>/</td>
        <td>/</td>
        <td>/</td>
        <td>/</td>
        <td>/</td>
      </tr>
    </table>

    <table class="table-score" border="1" style="width:100%;text-align: center;margin-bottom:0px" cellspacing="0">
      <tr class="table-bg1">
        <td>序号</td>
        <td>日期</td>
        <td>类型</td>
        <td>标题</td>
        <td>内容摘要</td>
      </tr>
      <template v-if="youFen.personInvolvement">
        <tr v-for="(item, i) in youFen.personInvolvement.list" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.caseTime }}</td>
          <td>{{ item.dataType }}</td>
          <td style="width: 350px;">
            <el-popover placement="top" title="标题" width="350" trigger="hover" :content="item.title">
              <p slot="reference">{{ item.title }}</p>
            </el-popover>
          </td>
          <td style="width: 400px;">
            <el-popover placement="top" title="内容摘要" width="450" trigger="hover" :content="item.content">
              <p slot="reference">{{ item.content }}</p>
            </el-popover>
          </td>
        </tr>
      </template>
      <tr v-if="!youFen.personInvolvement || !youFen.personInvolvement.list || youFen.personInvolvement.list.length == 0">
        <td colspan="5">无数据</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getRiskReviewStoreAPI } from './api'
export default {
  name: 'JiShen',

  data() {
    return {
      detail: {},
      alipay: {},
      baiRong: {},
      youFen: {},
      xinYanSpray: {},
      xinYanProbe: {},
      xinYanDocument: {}
    }
  },

  mounted() {
    this.getRiskReview()
  },
  // filters: {
  //   handleRuleType(val) {
  //     let str = ''
  //     if (val == 1) {
  //       str = '前置'
  //     } else if (val == 2) {
  //       str = '弱'
  //     } else if (val == 3) {
  //       str = '强'
  //     }
  //     return str
  //   }
  // },
  methods: {
    async getRiskReview() {
      const res = await getRiskReviewStoreAPI(this.$route.params.id)
      this.detail = res
      this.alipay = res.alipay
      this.baiRong = res.baiRong
      this.youFen = res.youFen
      this.xinYanSpray = res.xinYanSpray
      this.xinYanProbe = res.xinYanProbe
      this.xinYanDocument = res.xinYanDocument
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding-bottom: 50px;
  table {
    color: #333;
    th {
      font-size: 12px !important;
    }
    td {
      font-size: 12px !important;
    }
  }
  .score-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .item {
      margin-right: 40px;
      span {
        font-weight: 600;
        margin-right: 5px;
        font-size: 18px;
      }
      .score {
        color: #bd3124;
        font-weight: 600;
      }
    }
  }
  .title {
    padding-left: 10px;
    position: relative;
    font-weight: 600;
    margin: 10px 0 !important;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      width: 4px;
      height: 12px;
      background-color: #00aeff;
    }
  }
  .sub-title {
    margin: 10px 0;
    font-weight: 600;
  }
  .table-score {
    border-color: #f0f2f5;
    th {
      padding: 5px;
      background-color: #dadada;
    }
    td {
      padding: 5px;
    }
  }
  .baoxi {
    .sug {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 56px;
      margin-bottom: 20px;
      .s {
        position: relative;
        flex: 1;
        margin: 0 5px;
        height: 25px;
        background-color: #7bc744;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 25px;
        .sug-tip {
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translate(-50%, -100%);
          background-color: red;
          border-radius: 5px;
          padding: 5px 20px;
          white-space: nowrap;
          .arrow {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 10px;
            height: 10px;
            background-color: red;
            transform: rotate(45deg) translateX(-50%) translateY(100%);
          }
        }
        .t1 {
          background-color: #7bc744;
          .arrow {
            background-color: #7bc744;
          }
        }
        .t2 {
          background-color: #50b5f9;
          .arrow {
            background-color: #50b5f9;
          }
        }
        .t3 {
          background-color: #3984f9;
          .arrow {
            background-color: #3984f9;
          }
        }
        .t4 {
          background-color: #f2b744;
          .arrow {
            background-color: #f2b744;
          }
        }
        .t5 {
          background-color: #ef6361;
          .arrow {
            background-color: #ef6361;
          }
        }
      }
      .rank1 {
        background-color: #7bc744;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      .rank2 {
        background-color: #50b5f9;
      }
      .rank3 {
        background-color: #3984f9;
      }
      .rank4 {
        background-color: #f2b744;
      }
      .rank5 {
        background-color: #ef6361;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
    .rank-tag {
      display: inline-block;
      color: #fff;
      padding: 2px 6px;
      border-radius: 6px;
    }
    .tag1 {
      background-color: #7bc744;
    }
    .tag2 {
      background-color: #50b5f9;
    }
    .tag3 {
      background-color: #3984f9;
    }
    .tag4 {
      background-color: #f2b744;
    }
    .tag5 {
      background-color: #ef6361;
    }
  }
  .table-important {
    border-color: #f0f2f5;
    th {
      padding: 5px;
      background-color: #e6a4ab;
      color: #333;
    }
    td {
      padding: 5px;
    }
  }
  .table-bg1 {
    td {
      background-color: #dadada;
    }
  }
}
</style>

<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName">
      <el-tab-pane label="基础信息" name="1"></el-tab-pane>
      <el-tab-pane label="机审信息" name="2"></el-tab-pane>
      <el-tab-pane label="三方数据" name="3"></el-tab-pane>
    </el-tabs>
    <!-- 基础信息 -->
    <div v-if="$route.query.orderType == 2">
      <DetailBaseStore ref="base" v-show="activeName == 1" @goJS="goJS"></DetailBaseStore>
    </div>
    <div v-if="$route.query.orderType != 2">
      <DetailBase ref="base" v-show="activeName == 1"></DetailBase>
    </div>
    <!-- 机审信息 -->
    <div v-show="$route.query.orderType == 2">
      <JiShenStore ref="riskReview" v-show="activeName == 2"></JiShenStore>
    </div>
    <div v-show="$route.query.orderType != 2">
      <SetailRiskReview ref="riskReview" v-show="activeName == 2"></SetailRiskReview>
    </div>

    <!-- 三方数据 -->
    <DetailRisk ref="risk" v-show="activeName == 3"></DetailRisk>
  </div>
</template>

<script>
import DetailBase from './detail-base.vue'
import DetailRisk from './detail-risk.vue'
import SetailRiskReview from './detail-risk-review.vue'
import DetailBaseStore from './detail-base-store.vue'
import JiShenStore from './ji-shen-store.vue'

export default {
  name: 'Detail',
  components: { DetailBase, DetailRisk, SetailRiskReview, DetailBaseStore, JiShenStore },
  data() {
    return {
      activeName: '1'
    }
  },
  beforeRouteLeave(to, from, next) {
    // ...
    if (to.name == 'OrderUpdateGoods') {
      this.$refs.base.flag = false
    }
    next()
  },
  mounted() {},

  methods: {
    goJS() {
      this.activeName = '2'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  min-width: 1150px;
  
  .title {
    font-weight: 600;
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>

<template>
  <div class="wrapper">
    <el-row :gutter="15">
      <el-col :span="12">
        <div class="title">客户基本信息</div>
        <IdCard :member="detail.member" :detail="detail" />
        <div class="title">补充资料评级</div>
        <ScoreProfile v-if="detail.orderType === 2" :flag.sync="flag" :scoreProfileInfo="detail.orderScoreProfile || {}" @goJiShen="goJiShen" @updateDetail="getDetail" />
        <div class="title">紧急联系人</div>
        <Contact :type="1" :flag.sync="flag" :member="detail.member" :orderInfo="detail" @updateDetail="getDetail" />
        <div class="title">收货信息</div>
        <Address :flag.sync="flag" :orderAddress="detail.orderAddress" :status="detail.status" @updateDetail="getDetail" />
      </el-col>
      <el-col :span="12">
        <div class="title">订单信息</div>
        <BaseInfo :detail="detail" @updateDetail="getDetail" />
        <div class="title">订单备注</div>
        <Contact :type="2" :flag.sync="flag" :member="detail.member" :orderInfo="detail" @updateDetail="getDetail" />
        <div class="title">发货信息</div>
        <SendInfo :orderDelivery="detail.orderDelivery || {}" :detail="detail" />
      </el-col>
    </el-row>

    <!-- <div style="white-space: pre-wrap;" v-html="demo"></div> -->
    <!-- 商品信息 -->
    <!-- <BaseInfo :detail="detail" @updateDetail="getDetail" /> -->
    <!-- <div class="title">身份证照片</div> -->
    <!-- <IdCard :member="detail.member" :detail="detail" /> -->
    <!-- 评分资料 -->
    <!-- <Job v-if="detail.orderType !== 2" :flag.sync="flag" :jobInfo="detail.memberProfile || {}" :member="detail.member" @updateDetail="getDetail" /> -->
    <!-- <ScoreProfile v-if="detail.orderType === 2" :flag.sync="flag" :scoreProfileInfo="detail.orderScoreProfile || {}" @updateDetail="getDetail" /> -->
    <!-- <Contact :flag.sync="flag" :member="detail.member" :orderInfo="detail" @updateDetail="getDetail" /> -->
    <!-- <div class="title">收货信息</div> -->
    <!-- <Address :flag.sync="flag" :orderAddress="detail.orderAddress" :status="detail.status" @updateDetail="getDetail" /> -->
    <!-- <div class="title">发货信息</div> -->
    <!-- <SendInfo :orderDelivery="detail.orderDelivery || {}" :detail="detail" /> -->
    <!--    <div class="title">客户归属</div>-->
    <!-- <SelectCustomer :status="detail.status" :flag.sync="flag" :kfUserId="detail.kfUserId" :enableAbm="detail.enableAbm" @updateDetail="getDetail" /> -->
    <!--    <div class="title">用户评分</div>-->
    <!--    <Rate :flag.sync="flag" :memberGradeLog="detail.memberGradeLog" @updateDetail="getDetail"></Rate>-->
    <!-- 账期 -->
    <template v-if="detail.orderBillList">
      <div class="title">账期</div>
      <Period :orderBillList="detail.orderBillList" />
    </template>

    <el-divider></el-divider>
    <div style="text-align:right">
      <el-button type="" size="small" @click="closePage">关 闭</el-button>
    </div>
    <div style="height:60px;"></div>
  </div>
</template>

<script>
import { getDetailAPI } from './api'
import BaseInfo from './modules/baseInfo.vue'
import Address from './modules/address.vue'
import SendInfo from './modules/send-info.vue'
import Contact from './modules/contact.vue'
import SelectCustomer from './modules/select-customer.vue'
// import Rate from './modules/rate.vue'
import IdCard from './modules/id-card.vue'
import Job from './modules/job.vue'
import ScoreProfile from './modules/score-profile.vue'
import Period from './modules/period.vue'

export default {
  name: 'DetailBase',
  components: { BaseInfo, Address, SendInfo, Contact, SelectCustomer, IdCard, Job, Period, ScoreProfile },
  data() {
    return {
      detail: {},
      flag: true
    }
  },

  mounted() {
    // this.getDetail()
  },
  activated() {
    this.getDetail()
  },
  methods: {
    goJiShen(){
      this.$emit('goJS')
    },
    async getDetail() {
      this.detail = await getDetailAPI(this.$route.params.id)
    },
    closePage() {
      this.emitAwindow()
      window.close()
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList()
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>

<template>
  <div class="wrapper">
    <el-descriptions direction="vertical" :column="5" border>
      <el-descriptions-item label="系统评分" :span="1" label-class-name="my-label">
        {{ scoreProfileData.sysHitScore >= 0 ? scoreProfileData.sysHitScore + '分' : '/' }}
        <el-button v-if="scoreProfileData.sysHitScore >= 0" style="margin-left:10px" type="primary" size="small" @click="scoreBillList">详情</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="系统评级" :span="1" label-class-name="my-label">
        <TagLv v-if="scoreProfileData.sysHitGrade == 'A'" name="A"></TagLv>
        <TagLv v-if="scoreProfileData.sysHitGrade == 'B'" name="B"></TagLv>
        <TagLv v-if="scoreProfileData.sysHitGrade == 'C'" name="C"></TagLv>
        <TagLv v-if="scoreProfileData.sysHitGrade == 'D'" name="D"></TagLv>
      </el-descriptions-item>
      <el-descriptions-item label="最终评级" :span="1" label-class-name="my-label">
        <TagLv v-if="scoreProfileData.finalHitGrade == 'A'" name="A"></TagLv>
        <TagLv v-if="scoreProfileData.finalHitGrade == 'B'" name="B"> </TagLv>
        <TagLv v-if="scoreProfileData.finalHitGrade == 'C'" name="C"></TagLv>
        <TagLv v-if="scoreProfileData.finalHitGrade == 'D'" name="D"> </TagLv>
      </el-descriptions-item>
      <el-descriptions-item label="审核建议" :span="2" content-class-name="my-content" label-class-name="my-label">
        <span style="color:#bd3124">{{ scoreProfileData.sysHitSuggestDesc }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="芝麻分" :span="1" label-class-name="my-label">{{ scoreProfileData.zmScore }}</el-descriptions-item>
      <el-descriptions-item label="信用情况" :span="1" label-class-name="my-label">{{ scoreProfileData.creditStatusDesc }}</el-descriptions-item>
      <el-descriptions-item label="租机历史" :span="1" label-class-name="my-label">{{ scoreProfileData.rentHistoryDesc }}</el-descriptions-item>
      <el-descriptions-item label="涉诉情况" :span="1" label-class-name="my-label">{{ scoreProfileData.involveStatusDesc }}</el-descriptions-item>
      <el-descriptions-item label="是否在读学生" :span="1" label-class-name="my-label">{{ scoreProfileData.inSchoolStatusDesc }}</el-descriptions-item>
      <el-descriptions-item label="工作单位" :span="2" label-class-name="my-label">{{ scoreProfileData.workCompany }}</el-descriptions-item>
      <el-descriptions-item label="岗位" :span="2" label-class-name="my-label">
        <span>{{ scoreProfileData.jobCategoryName }}</span>
        <!--        <span style="margin-left: 10px;">岗位备注：{{ scoreProfileData.jobName || '' }}</span>-->
      </el-descriptions-item>
      <el-descriptions-item label="薪资" label-class-name="my-label">{{ scoreProfileData.income }}</el-descriptions-item>
    </el-descriptions>

    <el-dialog title="系统评分详情" :visible.sync="scoreBillInfoDialog" width="800px">
      <div class="score-bill-title">
        <span>系统等级：{{ scoreProfileData.sysHitGrade }}</span>
        <span>系统评分：{{ scoreProfileData.sysHitScore }}</span>
        <span>系统建议：{{ scoreProfileData.sysHitSuggestDesc }}</span>
      </div>
      <div class="score-bill-table">
        <tp-table :showPagination="false" :isNeedSerialNumber="true" :tableData="scoreBillInfo.billList" :columns="columns" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getScoreBillInfoAPI } from './api'

const columns = [
  {
    label: '评分项命中规则',
    prop: 'ruleName',
    minWidth: '300',
    customRender(h, row) {
      return <p>{row['ruleName']}</p>
    }
  },
  {
    label: '分值',
    prop: 'hitScore',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['hitScore']}</p>
    }
  },
  {
    label: '审核建议',
    prop: 'hitSuggestDesc',
    minWidth: '100',
    customRender(h, row) {
      return <div>{row['hitSuggestDesc']}</div>
    }
  }
]

export default {
  name: 'ScoreProfile',
  props: {
    scoreProfileInfo: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columns,
      scoreProfileData: {
        orderNo: '',
        sysHitScore: '',
        sysHitSuggestDesc: '',
        sysHitGrade: '',
        finalHitGrade: '',
        zmScore: '',
        creditStatusDesc: '',
        rentHistoryDesc: '',
        involveStatusDesc: '',
        inSchoolStatusDesc: '',
        workCompany: '',
        jobCategoryName: '',
        jobName: '',
        income: ''
      },
      scoreBillInfoDialog: false,
      scoreBillInfo: {}
    }
  },
  watch: {
    scoreProfileInfo: {
      immediate: true,
      handler(newV, oldV) {
        if (newV && this.flag) this.scoreProfileData = this.scoreProfileInfo
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    scoreBillList() {
      this.$emit('goJiShen')
      // getScoreBillInfoAPI({ orderNo: this.scoreProfileData.orderNo }).then(res => {
      //   this.scoreBillInfoDialog = true
      //   this.scoreBillInfo = res
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  border: 1px solid #ebeef5;
  padding: 10px;
  .el-descriptions {
    th,td{
      font-size: 13px;
      text-align: center;
      padding: 7px;
      height: auto;
      color: #606266;
    }
    .my-label {
      text-align: center;
      color: #1a1e25;
      background: #dadada;
      font-size: 13px;
    }
    .my-content {
      text-align: center;
      font-size: 13px;
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .el-descriptions-item__cell {
    height: 50px;
  }
  .score-bill-title {
    & > span {
      margin-right: 50px;
      font-weight: 600;
    }
  }
  .score-bill-table {
    margin-top: 20px;
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
}
</style>

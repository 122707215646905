<template>
  <div class="wrapper">
    <!-- 门店样式 -->
    <div class="store-box" v-if="detail.orderType == 2">
      <template v-if="member">
        <table class="table-box" style="width:100%" border>
          <tr class="table-bg1">
            <td>姓名</td>
            <td colspan="2">用户库类别</td>
            <td colspan="2">身份证照片</td>
          </tr>
          <tr>
            <td>{{ member.memberName }}</td>
            <td colspan="2">
              <span v-for="(item, i) in detail.rosterLevelList" :key="i">
                <span class="tag-ku t3" v-if="item == 1" style="margin:2px">白名单</span>
                <span class="tag-ku t1" v-if="item == 2" style="margin:2px">黑名单</span>
                <span class="tag-ku t2" v-if="item == 3" style="margin:2px">灰名单</span>
                <!-- <el-tag v-if="item == 1" style="margin:2px" size="small" type="primary">白名单</el-tag>
                <el-tag v-if="item == 2" style="margin:2px" size="small" type="danger">黑名单</el-tag>
                <el-tag v-if="item == 3" style="margin:2px" size="small" type="info">灰名单</el-tag> -->
              </span>
              <el-tag v-if="detail.rosterLevelList == 0" style="margin-right:5px" size="small" type="primary">无数据</el-tag>
            </td>
            <td colspan="2" rowspan="3">
              <div class="id-box">
                <el-image style="width: 100px; height: 100px;margin-right:10px;" :src="member.identityFrontImageUrl" :preview-src-list="[member.identityFrontImageUrl]">
                </el-image>
                <el-image style="width: 100px; height: 100px;" :src="member.identityBackImageUrl" :preview-src-list="[member.identityBackImageUrl]"> </el-image>
              </div>
            </td>
          </tr>
          <tr class="table-bg1">
            <td>手机号</td>
            <td>身份证号</td>
            <td>年龄</td>
          </tr>
          <tr>
            <td>{{ member.mobile }}</td>
            <td>
              {{ member.identityNoPlain }}
              <el-tag type="danger" size="small">历史支付：{{ detail.idCardHistoryPayNum }}单</el-tag>
            </td>
            <td>
              {{ member.age }}
            </td>
          </tr>
        </table>
      </template>
    </div>
    <div v-else class="id-card">
      <div class="img">
        <template v-if="member">
          <el-image style="width: 100px; height: 100px;margin-right:20px;" :src="member.identityFrontImageUrl" :preview-src-list="[member.identityFrontImageUrl]">
          </el-image>
          <el-image style="width: 100px; height: 100px;margin-right:20px;" :src="member.identityBackImageUrl" :preview-src-list="[member.identityBackImageUrl]">
          </el-image>
        </template>
      </div>
      <div class="info">
        <template v-if="member">
          <el-descriptions direction="vertical" :column="6" border>
            <el-descriptions-item label="姓名" label-class-name="my-label">{{ member.memberName }}</el-descriptions-item>
            <el-descriptions-item label="用户库类别" :span="1" label-class-name="my-label">
              <span v-for="(item, i) in detail.rosterLevelList" :key="i">
                <span class="tag-ku t3" v-if="item == 1" style="margin:2px">白名单</span>
                <span class="tag-ku t1" v-if="item == 2" style="margin:2px">黑名单</span>
                <span class="tag-ku t2" v-if="item == 3" style="margin:2px">灰名单</span>
              </span>
              <el-tag v-if="detail.rosterLevelList == 0" style="margin-right:5px" size="small" type="primary">无数据</el-tag>
            </el-descriptions-item>
            <!-- <el-descriptions-item label="性别" label-class-name="my-label">{{ member.gradeName }}</el-descriptions-item> -->
            <el-descriptions-item label="年龄" label-class-name="my-label">{{ member.age }}</el-descriptions-item>
            <el-descriptions-item label="手机号" label-class-name="my-label">{{ member.mobile }}</el-descriptions-item>
            <el-descriptions-item label="身份证号" :span="2" label-class-name="my-label">
              {{ member.identityNoPlain }}
              <el-tag type="danger" size="small">历史支付：{{ detail.idCardHistoryPayNum }}单</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdCard',
  props: {
    member: {
      type: Object,
      default: () => {}
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .store-box {
    border: 1px solid #ebeef5;
    padding: 10px;
  }
  .id-card {
    display: flex;
    align-items: center;
    // .img {
    // }
    .info {
      flex: 1;
    }
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #dadada;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .table-box {
    border-color: #ebeef5;
    font-size: 13px;
    border-collapse: collapse;
    border-width: 1px;
    border-spacing: 0;
    th {
      text-align: center;
      padding: 7px;
      background-color: #dadada;
    }
    td {
      text-align: center;
      padding: 7px;
      color: #606266;
      border: 1px solid #ebeef5;
    }
    .table-bg1 {
      td {
        color: #333;
        white-space: nowrap;
        background-color: #dadada;
      }
    }
    .id-box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tag-ku {
    padding: 3px 10px;
    border-radius: 3px;
    font-size: 12px;
  }
  .t1 {
    background-color: #101010;
    color: #fff;
  }
  .t2 {
    background-color: #bbbbbb;
    color: #fff;
  }
  .t3 {
    background-color: #fff;
    color: #101010;
    border: 1px solid #dadada;
  }
}
</style>
